import { computed } from 'vue'
import { i18n } from '../plugins/i18n'
import useTranslateSzComponents from '@sz4/sz-components/src/composables/useTranslate'
import { StringOrNumber } from '@/types'

export default function useTranslate() {
  const { translate: szComponentsTranslate } = useTranslateSzComponents()

  const translate = computed(() => ({
    lang: {
      ptbr: i18n.t('authenticator.lang.ptbr'),
      es: i18n.t('authenticator.lang.es'),
      en: i18n.t('authenticator.lang.en')
    },
    common: {
      language: i18n.t('authenticator.common.language'),
      forgot_your_password: i18n.t('authenticator.common.forgot_your_password'),
      signin_email: i18n.t('authenticator.common.signin_email'),
      back_to_signin: i18n.t('authenticator.common.back_to_signin'),
      password: i18n.t('authenticator.common.password'),
      password_confirm: i18n.t('authenticator.common.password_confirm'),
      or: i18n.t('authenticator.common.or'),
      recommended: i18n.t('authenticator.common.recommended'),
      i_didnt_receive_the_code: i18n.t('authenticator.common.i_didnt_receive_the_code'),
      signed_with_another_account: i18n.t('authenticator.common.signed_with_another_account'),
      actions: {
        signin: i18n.t('authenticator.common.actions.signin'),
        validate: i18n.t('authenticator.common.actions.validate'),
        reset_password: i18n.t('authenticator.common.actions.reset_password'),
        signin_with: (target: string) =>
          i18n.t('authenticator.common.actions.signin_with', { target }),
        send_again: i18n.t('authenticator.common.actions.send_again'),
        redirect_now: i18n.t('authenticator.common.actions.redirect_now'),
        copy_the_code: i18n.t('authenticator.common.actions.copy_the_code'),
        enabled: {
          he: i18n.t('authenticator.common.actions.enabled.he'),
          she: i18n.t('authenticator.common.actions.enabled.she')
        },
        confirmed: {
          he: i18n.t('authenticator.common.actions.confirmed.he'),
          she: i18n.t('authenticator.common.actions.confirmed.she')
        }
      },
      messages: {
        invalid_code: i18n.t('authenticator.common.messages.invalid_code'),
        passwords_do_not_match: i18n.t('authenticator.common.messages.passwords_do_not_match'),

        generic_error: i18n.t('authenticator.common.messages.generic_error'),
        multi_factor_required: i18n.t('authenticator.common.messages.multi_factor_required'),
        generic_not_found: i18n.t('authenticator.common.messages.generic_not_found'),
        login_failed: i18n.t('authenticator.common.messages.login_failed'),
        request_token_failed: i18n.t('authenticator.common.messages.request_token_failed'),
        validate_token_failed: i18n.t('authenticator.common.messages.validate_token_failed'),
        setup_token_failed: i18n.t('authenticator.common.messages.setup_token_failed'),
        reset_password_failed: i18n.t('authenticator.common.messages.reset_password_failed'),
        request_reset_password_failed: i18n.t(
          'authenticator.common.messages.request_reset_password_failed'
        ),
        unenroll_token_failed: i18n.t('authenticator.common.messages.unenroll_token_failed'),
        email_setup_error: i18n.t('authenticator.common.messages.email_setup_error'),
        enroll_cannot_password: i18n.t('authenticator.common.messages.enroll_cannot_password'),
        verify_email_and_password_failed: i18n.t(
          'authenticator.common.messages.verify_email_and_password_failed'
        ),
        auth_multi_factor_auth_required_failed: i18n.t(
          'authenticator.common.messages.auth_multi_factor_auth_required_failed'
        ),
        session_not_found: i18n.t('authenticator.common.messages.session_not_found'),
        invalid_api_key_forbidden: i18n.t(
          'authenticator.common.messages.invalid_api_key_forbidden'
        ),
        invalid_host_forbidden: i18n.t('authenticator.common.messages.invalid_host_forbidden'),
        firebase_set_otp_tenant_failed: i18n.t(
          'authenticator.common.messages.firebase_set_otp_tenant_failed'
        ),
        firebase_check_id_token: i18n.t('authenticator.common.messages.firebase_check_id_token'),
        firebase_user_not_found: i18n.t('authenticator.common.messages.firebase_user_not_found'),
        firebase_unroll_token_failed: i18n.t(
          'authenticator.common.messages.firebase_unroll_token_failed'
        ),
        firebase_sign_in_with_email_and_password_failed: i18n.t(
          'authenticator.common.messages.firebase_sign_in_with_email_and_password_failed'
        ),
        code_sended_with_success: i18n.t('authenticator.common.messages.code_sended_with_success')
      }
    },
    pages_titles: {
      'sign-in': i18n.t('authenticator.pages_titles.sign-in'),
      '2fa': i18n.t('authenticator.pages.two_fa.common.two_step_verification'),
      'confirm-code': i18n.t('authenticator.pages_titles.confirm-code'),
      forgot: i18n.t('authenticator.pages.forgot.title'),
      'new-password': i18n.t('authenticator.common.actions.reset_password'),
      'contact-support': i18n.t('authenticator.pages.contact_suport.title')
    },
    pages: {
      signin: {
        title: i18n.t('authenticator.pages.sign-in.title')
      },
      forgot: {
        title: i18n.t('authenticator.pages.forgot.title'),
        labels: {
          insert_your_email: i18n.t('authenticator.pages.forgot.labels.insert_your_email'),
          password_link_has_been_sent: (email: string) =>
            i18n.t('authenticator.pages.forgot.labels.password_link_has_been_sent', { email })
        }
      },
      two_fa: {
        common: {
          two_step_verification: i18n.t('authenticator.pages.two_fa.common.two_step_verification')
        },
        verification_method: {
          subtitle: i18n.t('authenticator.pages.two_fa.verification_method.subtitle'),
          enum: {
            mfas: {
              email: {
                title: i18n.t(
                  'authenticator.pages.two_fa.verification_method.enum.mfas.email.title'
                ),
                description: (accountName: string) =>
                  i18n.t(
                    'authenticator.pages.two_fa.verification_method.enum.mfas.email.description',
                    { accountName }
                  )
              },
              totp: {
                title: i18n.t(
                  'authenticator.pages.two_fa.verification_method.enum.mfas.totp.title'
                ),
                description: (accountName: string) =>
                  i18n.t(
                    'authenticator.pages.two_fa.verification_method.enum.mfas.totp.description',
                    { accountName }
                  )
              }
            }
          }
        },
        pre_code_check: {
          email: {
            an_email_will_be_sent: i18n.t(
              'authenticator.pages.two_fa.pre_code_check.email.an_email_will_be_sent'
            ),
            we_will_send_a_link_to_email: i18n.t(
              'authenticator.pages.two_fa.pre_code_check.email.we_will_send_a_link_to_email'
            )
          },
          totp: {
            receive_verification_codes: i18n.t(
              'authenticator.pages.two_fa.pre_code_check.totp.receive_verification_codes'
            ),
            first_download_the_app: i18n.t(
              'authenticator.pages.two_fa.pre_code_check.totp.first_download_the_app'
            )
          }
        },
        code_checker: {
          title: i18n.t('authenticator.pages.two_fa.code_checker.title'),
          we_send_a_code_to_your_mail: i18n.t(
            'authenticator.pages.two_fa.code_checker.we_send_a_code_to_your_mail'
          ),
          may_take_few_minutes: i18n.t(
            'authenticator.pages.two_fa.code_checker.may_take_few_minutes'
          ),
          have_not_received: i18n.t('authenticator.pages.two_fa.code_checker.have_not_received'),
          time_left_to_resend_code: (timer?: StringOrNumber) =>
            i18n.t('authenticator.pages.two_fa.code_checker.time_left_to_resend_code', {
              timer
            }),
          alternatively_you_can: i18n.t(
            'authenticator.pages.two_fa.code_checker.alternatively_you_can'
          ),
          insert_your_validation_code: i18n.t(
            'authenticator.pages.two_fa.code_checker.insert_your_validation_code'
          ),
          use_the_app_to_scan_your_code: i18n.t(
            'authenticator.pages.two_fa.code_checker.use_the_app_to_scan_your_code'
          )
        },
        confirmation: {
          title: (target: string) =>
            i18n.t('authenticator.pages.two_fa.confirmation.title', { target }),
          labels: {
            activation: (data: { target: string; time?: StringOrNumber }) =>
              i18n.t('authenticator.pages.two_fa.confirmation.labels.activation', data),
            confirmation: (time?: StringOrNumber) =>
              i18n.t('authenticator.pages.two_fa.confirmation.labels.confirmation', { time })
          }
        }
      },
      confirm_code: {
        pre_code_check: {
          subtitle: i18n.t('authenticator.pages.confirm_code.pre_code_check.subtitle')
        }
      },
      new_password: {
        texts: {
          confirm_password_reset: (email: string) =>
            i18n.t('authenticator.pages.new_password.texts.confirm_password_reset', { email })
        }
      },
      contact_suport: {
        title: i18n.t('authenticator.pages.contact_suport.title'),
        texts: {
          reset_password: i18n.t('authenticator.pages.contact_suport.texts.reset_password')
        }
      }
    },
    ...szComponentsTranslate.value
  }))

  return translate
}
