import { FirebaseUserCredential, MFAsType, UserBase } from '../models'
import { Endpoint } from '../rest'

export class Auth extends Endpoint {
  constructor(endpoint: string) {
    super(endpoint, 'auth')
  }

  requestToken(data: UserBase & { mfaId: MFAsType }) {
    return this.request<number>({
      method: 'POST',
      url: '/request-token',
      public: true,
      data
    })
  }

  setupMFA(data: {
    email: string
    password?: string
    mfaId: MFAsType
    verificationCode: string
    type: MFAsType
  }) {
    return this.request<FirebaseUserCredential>({
      method: 'POST',
      url: '/setup-mfa',
      public: true,
      hideNotificationError: true,
      data: {
        ...data,
        enroll: !!data.password
      }
    })
  }

  confirmCode(data: UserBase & { tokenCode: string }) {
    return this.request<FirebaseUserCredential>({
      method: 'POST',
      url: '/validate-token',
      public: true,
      hideNotificationError: true,
      data
    })
  }

  getUserMfas(data: UserBase) {
    return this.request<{
      mfa: {
        '2fa_method': MFAsType
        methods: MFAsType[]
      }
    }>({
      method: 'POST',
      url: '/get-user-mfas',
      public: true,
      data
    })
  }

  requestResetPassword(email: string) {
    return this.request({
      method: 'POST',
      url: '/request-reset-password',
      public: true,
      data: {
        email
      }
    })
  }

  confirmResetPassword(data: { token: string; email: string; newPassword: string }) {
    return this.request({
      method: 'POST',
      url: '/confirm-reset-password',
      public: true,
      data
    })
  }
}
