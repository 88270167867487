import { Consumer } from '../models'
import { Endpoint } from '../rest'

export class Consumers extends Endpoint {
  constructor(endpoint: string) {
    super(endpoint, 'consumer')
  }

  getContext(sourceHost: string) {
    return this.request<Consumer>({
      method: 'GET',
      url: '/' + sourceHost,
      public: true
    })
  }
}
